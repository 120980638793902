import { createIcon } from '@chakra-ui/icons';

export const SympeeLogo = createIcon({
    displayName: 'SympeeLogo',
    viewBox: '0 0 160 37',
    path: (
        <svg width='160' height='37' viewBox='0 0 160 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_5602_33335)'>
                <path
                    d='M151.604 14.8596C149.32 14.8596 147.692 16.1224 146.946 17.9482C146.791 18.3278 147.092 18.7162 147.502 18.7162H155.547C155.95 18.7162 156.251 18.3406 156.109 17.9634C155.415 16.1104 153.815 14.8596 151.603 14.8596H151.604ZM151.604 11.9071C156.742 11.9071 160.062 15.7388 160.001 20.8967C159.997 21.2282 159.72 21.4877 159.388 21.4877H147.198C146.829 21.4877 146.536 21.8056 146.607 22.1676C147.117 24.7805 149.136 26.2179 151.694 26.2179C153.536 26.2179 155.093 25.494 155.936 24.0943C156.06 23.8877 156.276 23.7467 156.518 23.7467H158.764C159.197 23.7467 159.499 24.1743 159.318 24.5667C157.865 27.7106 154.919 29.1993 151.665 29.1993C146.634 29.1993 143.049 25.5837 143.049 20.5828C143.049 15.5819 146.603 11.9062 151.605 11.9062L151.604 11.9071Z'
                    fill='#034D21'
                />
                <path
                    d='M133.698 14.8596C131.415 14.8596 129.787 16.1224 129.041 17.9482C128.886 18.3278 129.187 18.7162 129.597 18.7162H137.642C138.045 18.7162 138.345 18.3406 138.204 17.9634C137.51 16.1104 135.91 14.8596 133.698 14.8596H133.698ZM133.698 11.9071C138.837 11.9071 142.157 15.7388 142.096 20.8967C142.092 21.2282 141.815 21.4877 141.483 21.4877H129.293C128.924 21.4877 128.631 21.8056 128.702 22.1676C129.212 24.7805 131.231 26.2179 133.789 26.2179C135.631 26.2179 137.188 25.494 138.031 24.0943C138.155 23.8877 138.371 23.7467 138.613 23.7467H140.859C141.292 23.7467 141.593 24.1743 141.412 24.5667C139.96 27.7106 137.014 29.1993 133.76 29.1993C128.729 29.1993 125.144 25.5837 125.144 20.5828C125.144 15.5819 128.698 11.9062 133.7 11.9062L133.698 11.9071Z'
                    fill='#034D21'
                />
                <path
                    d='M115.69 25.9457C118.552 25.9457 120.631 23.7467 120.631 20.5532C120.631 17.3596 118.582 15.1599 115.69 15.1599C112.797 15.1599 110.598 17.3893 110.598 20.5532C110.598 23.7171 112.797 25.9457 115.69 25.9457ZM116.443 11.9062C120.782 11.9062 124.066 15.4914 124.066 20.5532C124.066 25.615 120.842 29.2001 116.414 29.2001C114.657 29.2001 113.177 28.6179 112.03 27.661C111.55 27.2598 110.629 27.5713 110.629 28.1975V35.7433C110.629 36.0901 110.348 36.3712 110.001 36.3712H107.852C107.505 36.3712 107.224 36.0901 107.224 35.7433V12.8952C107.224 12.5485 107.505 12.2674 107.852 12.2674H110.001C110.348 12.2674 110.629 12.5485 110.629 12.8952V12.9401C110.629 13.5687 111.557 13.8786 112.036 13.4726C113.184 12.4996 114.669 11.9062 116.443 11.9062Z'
                    fill='#034D21'
                />
                <path
                    d='M99.4472 11.9062C102.942 11.9062 105.051 14.2566 105.051 18.2029V28.2103C105.051 28.5571 104.77 28.8381 104.423 28.8381H102.274C101.927 28.8381 101.646 28.5571 101.646 28.2103V18.926C101.646 16.4555 100.652 15.1599 98.3622 15.1599C95.6811 15.1599 94.295 17.2387 94.295 20.2513L94.323 28.2079C94.3246 28.5554 94.0427 28.8381 93.6952 28.8381H91.5178C91.1711 28.8381 90.89 28.5571 90.89 28.2103V18.926C90.89 16.4555 89.8954 15.1599 87.606 15.1599C84.9249 15.1599 83.5692 17.2387 83.5692 20.2513V28.2103C83.5692 28.5571 83.2881 28.8381 82.9414 28.8381H80.7921C80.4453 28.8381 80.1642 28.5571 80.1642 28.2103V12.8952C80.1642 12.5485 80.4453 12.2674 80.7921 12.2674H82.9918C83.3105 12.2674 83.5692 12.5261 83.5692 12.8448C83.5692 13.4294 84.4333 13.7016 84.8673 13.31C85.8675 12.4075 87.1255 11.9062 88.7215 11.9062C90.721 11.9062 92.2506 12.6774 93.2059 14.1012C93.5102 14.5545 94.2741 14.6033 94.6137 14.1757C95.746 12.7495 97.3572 11.9062 99.4472 11.9062Z'
                    fill='#034D21'
                />
                <path
                    d='M75.4355 12.6388C75.5364 12.4122 75.7614 12.2656 76.0089 12.2656H78.2711C78.7276 12.2656 79.0319 12.7373 78.8421 13.1529L68.447 36.0002C68.3453 36.2244 68.1219 36.3678 67.8761 36.3678H65.6082C65.1518 36.3678 64.8483 35.8969 65.0365 35.4813L68.8859 26.9857C68.9603 26.8216 68.9603 26.6326 68.8859 26.4684L62.8655 13.1521C62.6774 12.7365 62.9817 12.2656 63.4373 12.2656H65.6987C65.9486 12.2656 66.1744 12.4138 66.2745 12.6428L70.24 21.7438C70.4578 22.2443 71.1673 22.2467 71.3891 21.7478L75.4347 12.6388H75.4355Z'
                    fill='#034D21'
                />
                <path
                    d='M55.6184 29.2141C51.5544 29.2141 48.5603 27.3891 47.6522 24.2492C47.5441 23.8744 47.8412 23.5205 48.232 23.5205H50.4718C50.7537 23.5205 50.9955 23.7111 51.0996 23.9729C51.705 25.4976 53.0623 26.2023 55.6785 26.2023C57.8775 26.2023 59.234 25.5393 59.234 24.3045C59.234 22.9183 57.6677 22.7077 54.8953 22.0446C50.2556 20.8995 48.2976 19.9658 48.2976 17.1943C48.2976 13.8502 51.13 11.9219 55.2877 11.9219C59.1772 11.9219 61.5002 13.6139 62.3275 16.2117C62.4476 16.5889 62.1489 16.9532 61.7525 16.9532H59.5263C59.2604 16.9532 59.0306 16.7819 58.9049 16.548C58.3251 15.4654 57.1135 14.9344 55.2269 14.9344C53.0279 14.9344 51.7322 15.688 51.7322 16.8323C51.7322 17.887 52.7564 18.1576 55.6489 18.8511C60.1381 19.9057 62.7286 20.6889 62.7286 23.9129C62.7286 27.4684 59.5351 29.2157 55.6184 29.2157V29.2141Z'
                    fill='#034D21'
                />
                <path d='M138.831 5.1255H136.904V9.90701H135.686V5.1255H133.748V3.98438H138.83V5.1255H138.831Z' fill='#034D21' />
                <path
                    d='M145.55 7.26872H140.737C140.834 8.35619 141.598 8.95919 142.589 8.95919C143.3 8.95919 143.892 8.65729 144.172 8.07592H145.432C144.969 9.40042 143.838 10.025 142.578 10.025C140.779 10.025 139.498 8.73257 139.498 6.9452C139.498 5.15784 140.769 3.84375 142.556 3.84375C144.462 3.84375 145.668 5.3188 145.55 7.26792L145.55 7.26872ZM140.802 6.27815H144.247C144.064 5.47015 143.451 4.89999 142.557 4.89999C141.663 4.89999 141.007 5.48136 140.802 6.27815Z'
                    fill='#034D21'
                />
                <path
                    d='M152.658 6.97618C152.658 8.818 151.344 10.0344 149.653 10.0344C147.963 10.0344 146.573 8.90368 146.563 6.41643C146.563 2.20589 148.63 1.48438 151.914 1.48438V2.63671C149.222 2.63671 147.973 3.05632 147.801 5.36099C148.232 4.54259 149.082 4.12297 149.976 4.12297C151.484 4.12297 152.657 5.25369 152.657 6.97699L152.658 6.97618ZM151.44 7.04105C151.44 5.91033 150.676 5.1672 149.663 5.1672C148.651 5.1672 147.79 5.89912 147.79 7.04105C147.79 8.18297 148.576 8.97976 149.599 8.97976C150.621 8.97976 151.44 8.22622 151.44 7.04105Z'
                    fill='#034D21'
                />
                <path
                    d='M159.753 7.26872H154.94C155.037 8.35619 155.801 8.95919 156.792 8.95919C157.503 8.95919 158.095 8.65729 158.375 8.07592H159.635C159.172 9.40042 158.041 10.025 156.781 10.025C154.982 10.025 153.701 8.73257 153.701 6.9452C153.701 5.15784 154.972 3.84375 156.759 3.84375C158.665 3.84375 159.871 5.3188 159.752 7.26792L159.753 7.26872ZM155.004 6.27815H158.45C158.267 5.47015 157.654 4.89999 156.76 4.89999C155.866 4.89999 155.209 5.48136 155.004 6.27815Z'
                    fill='#034D21'
                />
                <path
                    d='M124.258 5.49993C124.243 4.48853 124.951 3.62528 125.936 3.41948C126.472 3.30737 126.972 3.40987 127.432 3.69655C127.603 3.80306 127.759 3.93279 127.919 4.05531C127.953 4.08173 127.972 4.08414 128.004 4.05531C128.266 3.82148 128.552 3.61968 128.887 3.50437C129.694 3.22729 130.411 3.40507 131.029 3.97683C131.39 4.30996 131.592 4.73598 131.65 5.22366C131.695 5.60483 131.645 5.9804 131.549 6.34957C131.369 7.03825 131.016 7.63483 130.562 8.17617C130.081 8.75033 129.513 9.226 128.897 9.64802C128.732 9.76093 128.566 9.87144 128.395 9.97314C128.115 10.1389 127.822 10.1477 127.544 9.98114C126.886 9.58636 126.273 9.12911 125.732 8.58217C125.231 8.07607 124.812 7.51151 124.54 6.84686C124.363 6.41523 124.262 5.96759 124.258 5.49993ZM130.638 5.50874C130.627 5.41905 130.623 5.32776 130.603 5.23967C130.459 4.59264 129.713 4.20986 129.123 4.52937C128.833 4.68713 128.591 4.90014 128.384 5.15479C128.15 5.44307 127.775 5.44308 127.542 5.15639C127.348 4.91776 127.124 4.71356 126.855 4.55981C126.582 4.40365 126.296 4.36521 126.001 4.47973C125.596 4.63588 125.356 4.93137 125.302 5.3654C125.271 5.61765 125.316 5.86429 125.382 6.10613C125.516 6.60422 125.772 7.03825 126.094 7.43544C126.609 8.07046 127.244 8.56535 127.926 9.00498C127.958 9.0258 127.981 9.01459 128.008 8.99778C128.4 8.74793 128.775 8.47486 129.124 8.16656C129.595 7.75095 130.009 7.28729 130.3 6.72514C130.497 6.34476 130.621 5.94277 130.638 5.50954V5.50874Z'
                    fill='#FD6B00'
                />
                <path
                    d='M9.92248 35.2381C9.92248 35.6914 9.55314 36.0573 9.09561 36.0573H2.21602C0.992248 36.0573 0 35.0742 0 33.8618V27.0458C0 26.5925 0.369337 26.2266 0.826873 26.2266C1.28441 26.2266 1.65375 26.5925 1.65375 27.0458V33.8618C1.65375 34.1703 1.90456 34.4188 2.21602 34.4188H9.09561C9.55314 34.4188 9.92248 34.7848 9.92248 35.2381Z'
                    fill='#034D21'
                />
                <path
                    d='M36.3824 27.0458V33.8618C36.3824 35.0742 35.3902 36.0573 34.1664 36.0573H27.2868C26.8293 36.0573 26.46 35.6914 26.46 35.2381C26.46 34.7848 26.8293 34.4188 27.2868 34.4188H34.1664C34.4779 34.4188 34.7287 34.1703 34.7287 33.8618V27.0458C34.7287 26.5925 35.098 26.2266 35.5556 26.2266C36.0131 26.2266 36.3824 26.5925 36.3824 27.0458Z'
                    fill='#FD6B00'
                />
                <path
                    d='M9.92248 0.834852C9.92248 1.28816 9.55314 1.65408 9.09561 1.65408H2.21602C1.90456 1.65408 1.65375 1.90258 1.65375 2.21115V9.02712C1.65375 9.48042 1.28441 9.84635 0.826873 9.84635C0.369337 9.84635 0 9.48042 0 9.02712V2.21115C0 0.998697 0.992248 0.015625 2.21602 0.015625H9.09561C9.55314 0.015625 9.92248 0.381546 9.92248 0.834852Z'
                    fill='#034D21'
                />
                <path
                    d='M27.2868 0.015625H34.1664C35.3902 0.015625 36.3824 0.998697 36.3824 2.21115V9.02712C36.3824 9.48042 36.0131 9.84635 35.5556 9.84635C35.098 9.84635 34.7287 9.48042 34.7287 9.02712V2.21115C34.7287 1.90258 34.4779 1.65408 34.1664 1.65408H27.2868C26.8293 1.65408 26.46 1.28816 26.46 0.834852C26.46 0.381546 26.8293 0.015625 27.2868 0.015625Z'
                    fill='url(#paint0_linear_5602_33335)'
                />
                <path
                    d='M15.6224 4.92188H6.35039C5.5814 4.92188 4.96124 5.5363 4.96124 6.29818V15.4844C4.96124 16.2463 5.5814 16.8607 6.35039 16.8607H15.6224C16.3914 16.8607 17.0115 16.2463 17.0115 15.4844V6.29818C17.0115 5.5363 16.3914 4.92188 15.6224 4.92188ZM15.3578 15.2223H6.61499V6.56033H15.3578V15.2223Z'
                    fill='#034D21'
                />
                <path
                    d='M12.213 8.30469H9.75994C8.9937 8.30469 8.37079 8.92184 8.37079 9.68099V12.1114C8.37079 12.8705 8.9937 13.4877 9.75994 13.4877H12.213C12.9792 13.4877 13.6021 12.8705 13.6021 12.1114V9.68099C13.6021 8.92184 12.9792 8.30469 12.213 8.30469ZM11.9484 11.8492H10.0245V9.94314H11.9484V11.8492Z'
                    fill='#034D21'
                />
                <path
                    d='M29.9466 4.92188H20.6746C19.9056 4.92188 19.2855 5.5363 19.2855 6.29818V15.4844C19.2855 16.2463 19.9056 16.8607 20.6746 16.8607H29.9466C30.7156 16.8607 31.3358 16.2463 31.3358 15.4844V6.29818C31.3358 5.5363 30.7156 4.92188 29.9466 4.92188ZM29.682 15.2223H20.9392V6.56033H29.682V15.2223Z'
                    fill='url(#paint1_linear_5602_33335)'
                />
                <path
                    d='M26.5372 8.30469H24.0842C23.3179 8.30469 22.695 8.92184 22.695 9.68099V12.1114C22.695 12.8705 23.3179 13.4877 24.0842 13.4877H26.5372C27.3034 13.4877 27.9264 12.8705 27.9264 12.1114V9.68099C27.9264 8.92184 27.3034 8.30469 26.5372 8.30469ZM26.2726 11.8492H24.3488V9.94314H26.2726V11.8492Z'
                    fill='url(#paint2_linear_5602_33335)'
                />
                <path
                    d='M15.6225 19.0859H6.35045C5.58146 19.0859 4.9613 19.7004 4.9613 20.4622V29.6485C4.9613 30.4104 5.58146 31.0248 6.35045 31.0248H15.6225C16.3914 31.0248 17.0116 30.4104 17.0116 29.6485V20.4622C17.0116 19.7004 16.3914 19.0859 15.6225 19.0859ZM15.3579 29.3863H6.61505V20.7244H15.3579V29.3863Z'
                    fill='#034D21'
                />
                <path
                    d='M12.213 22.4688H9.75994C8.9937 22.4688 8.37079 23.0859 8.37079 23.8451V26.2754C8.37079 27.0346 8.9937 27.6517 9.75994 27.6517H12.213C12.9792 27.6517 13.6021 27.0346 13.6021 26.2754V23.8451C13.6021 23.0859 12.9792 22.4688 12.213 22.4688ZM11.9484 26.0133H10.0245V24.1072H11.9484V26.0133Z'
                    fill='#034D21'
                />
                <path
                    d='M27.8381 19.7734C26.8513 19.7734 26.0327 20.295 25.5228 20.7128C25.4484 20.7756 25.3767 20.8357 25.3106 20.8958C25.2444 20.8357 25.1728 20.7756 25.0984 20.7128C24.5885 20.295 23.7698 19.7734 22.7831 19.7734C20.9392 19.7734 19.4315 21.2426 19.4315 23.0722C19.4315 23.744 19.5666 24.8199 20.3052 26.0897C21.0412 27.354 22.3504 28.7658 24.6188 30.1476H24.6215C24.831 30.2759 25.068 30.3415 25.3106 30.3415C25.5531 30.3415 25.7929 30.2759 25.9997 30.1503H26.0024C28.2736 28.7658 29.58 27.354 30.3159 26.0897C31.0546 24.8199 31.1897 23.744 31.1897 23.0722C31.1897 21.2426 29.682 19.7734 27.8381 19.7734ZM28.8827 25.2732C28.3259 26.2289 27.2785 27.4141 25.3106 28.6457C23.3426 27.4141 22.2953 26.2289 21.7385 25.2704C21.168 24.2901 21.0853 23.5064 21.0853 23.0722C21.0853 22.1628 21.8377 21.4119 22.7831 21.4119C23.1938 21.4119 23.6403 21.644 24.0427 21.9744C24.2329 22.1301 24.39 22.2885 24.4975 22.4086C24.5499 22.4687 24.5912 22.5151 24.6188 22.5479C24.6298 22.5643 24.6408 22.5752 24.6436 22.5807C24.6436 22.5834 24.6491 22.5861 24.6491 22.5861C24.8034 22.7937 25.0487 22.9138 25.3106 22.9138C25.5724 22.9138 25.8177 22.7937 25.9721 22.5861L25.9776 22.5807C25.9776 22.5807 25.9914 22.5643 26.0052 22.5479C26.03 22.5151 26.0713 22.4687 26.1237 22.4086C26.2312 22.2885 26.3883 22.1301 26.5785 21.9744C26.9836 21.644 27.4274 21.4119 27.8381 21.4119C28.7835 21.4119 29.5359 22.1628 29.5359 23.0722C29.5359 23.5064 29.4532 24.2901 28.8827 25.2732Z'
                    fill='url(#paint3_linear_5602_33335)'
                />
            </g>
            <defs>
                <linearGradient id='paint0_linear_5602_33335' x1='28.8083' y1='-0.161874' x2='36.7939' y2='6.41547' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.55' stop-color='#4C5617' />
                    <stop offset='1' stop-color='#DC6704' />
                </linearGradient>
                <linearGradient id='paint1_linear_5602_33335' x1='20.1923' y1='6.65317' x2='30.4901' y2='15.3383' gradientUnits='userSpaceOnUse'>
                    <stop stop-color='#0E4E20' />
                    <stop offset='1' stop-color='#FC6B00' />
                </linearGradient>
                <linearGradient id='paint2_linear_5602_33335' x1='22.9789' y1='9.89126' x2='26.7052' y2='11.5277' gradientUnits='userSpaceOnUse'>
                    <stop stop-color='#5F5815' />
                    <stop offset='1' stop-color='#F66A01' />
                </linearGradient>
                <linearGradient id='paint3_linear_5602_33335' x1='20.4568' y1='20.5299' x2='29.3283' y2='26.9131' gradientUnits='userSpaceOnUse'>
                    <stop stop-color='#D25900' />
                    <stop offset='1' stop-color='#FA6A00' />
                </linearGradient>
                <clipPath id='clip0_5602_33335'>
                    <rect width='160' height='36.4007' fill='white' />
                </clipPath>
            </defs>
        </svg>
    ),
});
